import { makeAutoObservable, runInAction } from "mobx";
import { ICustomerStore, Store, AllUserData } from "../global/types";
import { getAllCustomersData, getOneUserData } from "../api/user.action";

class CustomerStore implements ICustomerStore {
    rootStore: Store;
    allCustomersData: AllUserData[] = [];
    customerData: AllUserData | null = null;
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    logInType: string = '';
    totalItems: number = 0;
    totalPages: number = 0;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllCustomersData = async (
        action: 'change_page' | 'search' | 'change_limit' | 'change_login_type' | '',
        page: number,
        searchTerm: string,
        limit: number,
        logInType: string
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_limit':
                        this.limit = limit
                        this.page = 1
                        break;
                    case 'change_login_type':
                        this.logInType = logInType
                        this.page = 1
                        break;
                    default:
                        break;
                }
            })
            const data = {
                page: this.page,
                limit: this.limit,
                search: this.searchTerm?.trim(),
                login_type: this.logInType.toUpperCase()
            }
            const response = await getAllCustomersData(data);
            runInAction(() => {
                this.allCustomersData = response.data?.data?.users || [];
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            })
        } catch (error: unknown) {
            throw error
        } finally {
            this.setLoading(false);
        }
    }

    fetchOneCustomerData = async (id: string) => {
        this.setLoading(true);
        try {
            const response = await getOneUserData(id);
            runInAction(() => {
                this.customerData = response.data.data;
            })
        } catch (error: unknown) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }
}

export default CustomerStore;
