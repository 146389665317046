import { makeObservable } from "mobx";
import { IAuthStore, IBootlegStore, ICustomerStore, ITransactionHistoryStore,IConcertStore, Store, IDashboardStore } from "../global/types";
import AuthStore from "./AuthStore";
import BootlegStore from "./BootlegStore";
import CustomerStore from "./CustomerStore";
import TransactionHistoryStore from "./TransactionHistoryStore" 
import ConcertStore from "./ConcertStore";
import DashboardStore from "./DashboardStore";

class RootStore implements Store {
    public authStore: IAuthStore
    public bootlegStore: IBootlegStore
    public customerStore: ICustomerStore
    public transactionHistoryStore: ITransactionHistoryStore
    public concertStore: IConcertStore
    public dashboardStore: IDashboardStore

    constructor() {
        makeObservable(this);
        this.authStore = new AuthStore(this);
        this.bootlegStore = new BootlegStore(this);
        this.customerStore = new CustomerStore(this);
        this.transactionHistoryStore = new TransactionHistoryStore(this);
        this.concertStore = new ConcertStore(this);
        this.dashboardStore = new DashboardStore(this)
    }
}

export default RootStore;
