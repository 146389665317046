import React, { useState, useEffect, useContext } from "react";
import { Menu, UserCircle } from "lucide-react";
import { Link } from "react-router-dom";
import { RootContext } from "../../context";

const Header = ({toggleSidebar}: any) => {
  const { authStore } = useContext(RootContext);
  const { logout } = authStore;
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      !(event.target as HTMLElement).closest(".menu-button") &&
      !(event.target as HTMLElement).closest(".dropdown-menu")
    ) {
      setIsOpen(false);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="flex justify-between items-center p-1 border-b-2 h-12 px-4 border-gray-300">
      <Link to="/">
        <h1 className="text-2xl font-bold">Bootleg</h1>
      </Link>
      <div className="relative flex gap-1 items-center">
        <button
          onClick={toggleMenu}
          className="px-2 py-2 hover:bg-gray-100 rounded-md transition-colors"
        >
          <UserCircle />
        </button>
        <button
          onClick={toggleSidebar}
          className="md:hidden px-2 py-2 hover:bg-gray-100 rounded-md transition-colors"
        >
          <Menu />
        </button>
        {isOpen && (
          <div className="dropdown-menu z-10 absolute top-full right-2 mt-2 w-48 bg-gray-50 border border-gray-200 shadow-lg rounded-md">
            <ul>
              <li>
                <Link
                  to="/user/profile"
                  onClick={closeMenu}
                  className="block w-full px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-t-md"
                >
                  Profile
                </Link>
              </li>
              <li>
                <Link
                  to="/user/change-password"
                  onClick={closeMenu}
                  className="block w-full px-4 py-2 text-gray-700 hover:bg-gray-200"
                >
                  Change Password
                </Link>
              </li>
              <li>
                <button
                  onClick={() => handleLogout()}
                  className="block w-full px-4 py-2 text-gray-700 hover:bg-gray-200 text-left rounded-b-md"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
