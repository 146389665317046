import { makeAutoObservable, runInAction } from "mobx";
import { IBootlegStore, Store, CreateUserData, UserData, AllUserData } from "../global/types";
import { createBootleg, getAllBootlegData, getOneUserData, updateBootlegData } from "../api/user.action";

class BootlegStore implements IBootlegStore {
    rootStore: Store;
    allBootlegData: AllUserData[] = [];
    bootlegData: UserData | null = null;
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    totalItems: number = 0;
    totalPages: number = 0;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllBootlegData = async (
        action: 'change_page' | 'search' | 'change_limit' | '',
        page: number,
        searchTerm: string,
        limit: number
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_limit':
                        this.limit = limit
                        this.page = 1
                        break;
                    default:
                        break;
                }
            })
            const data = {
                page: this.page,
                limit: this.limit,
                search: this.searchTerm?.trim()
            }
            const response = await getAllBootlegData(data);
            runInAction(() => {
                this.allBootlegData = response.data?.data?.users || [];
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            })
        } catch (error: unknown) {
            throw error
        } finally {
            this.setLoading(false);
        }
    }

    fetchOneBootlegData = async (id: string) => {
        this.setLoading(true);
        try {
            const response = await getOneUserData(id);
            runInAction(() => {
                this.bootlegData = response.data.data;
            })
        } catch (error: unknown) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    updateBootlegDetails = async (data: CreateUserData) => {
        this.setLoading(true);
        try {
            const requestBody = {
                name: data.name,
                email: data.email,
                password: data.password,
                phone_number: data.phoneNumber,
                bootleg_id: this.bootlegData?._id
            }

            const response = await updateBootlegData(requestBody);
            runInAction(() => {
                this.bootlegData = response.data.data;
            })
        } catch (error: unknown) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    addNewBootleg = async (data: CreateUserData) => {
        this.setLoading(true);
        try {
            const requestBody = {
                name: data.name,
                email: data.email,
                password: data.password,
                phone_number: data.phoneNumber
            }

            const response = await createBootleg(requestBody);
            runInAction(() => {
                this.bootlegData = response.data.data;
            })
        } catch (error: unknown) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }
}

export default BootlegStore;
