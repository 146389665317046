import { makeAutoObservable, runInAction } from "mobx";
import { IConcertStore, Store, ConcertData, ConcertTypeValues, ConcertStatusValues, Genre, CreateConcertData, TrackSplittingType, AllConcertData } from "../global/types";
import { createConcert, getAllConcertData, getOneConcertData, getPreSignedUrlAudio, getPreSignedUrlImage, updateConcertData, updateTrackSplitData, uploadFileToS3 } from "../api/concert.action";

class ConcertStore implements IConcertStore {
    rootStore: Store;
    allConcertData: AllConcertData[] = [];
    concertData: ConcertData | null = null;
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    concertType: ConcertTypeValues = 'ALL';
    concertStatus: ConcertStatusValues = 'ALL';
    totalItems: number = 0;
    totalPages: number = 0;
    audioUploadProgress: number = 0;
    isAudioUploading: boolean = false;
    audioUploadComplete: boolean = false;
    audioURL: string | null = null;
    concertDuration: string | null = null;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllConcertData = async (
        action: 'change_page' | 'search' | 'change_filter' | '',
        page: number,
        searchTerm: string,
        limit: number,
        concertType: ConcertTypeValues,
        concertStatus: ConcertStatusValues
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_filter':
                        this.limit = limit;
                        this.concertType = concertType;
                        this.concertStatus = concertStatus;
                        this.page = 1;
                        break;
                    default:
                        break;
                }
            });
            const params = {
                page: this.page,
                limit: this.limit,
                search: this.searchTerm,
                concert_type: this.concertType.toUpperCase(),
                concert_status: this.concertStatus.toUpperCase()
            }
            const response = await getAllConcertData(params);
            runInAction(() => {
                this.allConcertData = response.data?.data?.concerts || [];
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            });
        } catch (error) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    fetchOneConcertData = async (id: string) => {
        this.setLoading(true);
        try {
            const response = await getOneConcertData(id);
            const data = response.data?.data;
            const formattedGenre = data.genre.map((genre: string) => ({
                value: genre,
                label: genre.charAt(0).toUpperCase() + genre.slice(1)
            }));
            const formattedType = {
                value: data.type,
                label: data.type.charAt(0).toUpperCase() + data.type.slice(1).toLocaleLowerCase()
            }
            const date = new Date(data?.date);
            const formattedDate = date.toISOString().split('T')[0];
            runInAction(() => {
                this.concertData = {
                    ...data,
                    genre: formattedGenre,
                    type: formattedType,
                    date: formattedDate
                };
                this.audioURL = data.file_url;
                this.concertDuration = data.length;
            });
        } catch (error) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    updateConcertDetails = async (id: string, data: ConcertData) => {
        this.setLoading(true);
        try {
            const requestBody: CreateConcertData = {
                ...data,
                genre: data.genre.map((genre: Genre) => genre.value),
                type: data.type.value,
                file_url: this.getUrlEndpoint(this.audioURL!),
                image: this.getUrlEndpoint(data.image),
                length: this.concertDuration!,
                concert_id: id,
            }
            const response = await updateConcertData(requestBody);
            runInAction(() => {
                this.resetConcertData();
                this.concertData = response.data;
            });
        } catch (error) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    addNewConcert = async (data: ConcertData): Promise<ConcertData> => {
        this.setLoading(true);
        try {
            const requestBody: CreateConcertData = {
                ...data,
                genre: data.genre.map((genre: Genre) => genre.value),
                type: data.type.value,
                file_url: this.getUrlEndpoint(this.audioURL!),
                length: this.concertDuration!,
                image: this.getUrlEndpoint(data.image),
            }
            const response = await createConcert(requestBody);
            runInAction(() => {
                this.concertData = response.data?.data;
            });
            return response.data?.data;
        } catch (error) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    resetConcertData = () => {
        this.concertData = null;
        this.audioURL = null;
        this.concertDuration = null;
    }

    fetchSecretToken = async (file_name: string, content_type: string) => {
        try {
            const response = await getPreSignedUrlAudio(file_name, content_type);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    uploadAudio = async (file: File) => {
        this.isAudioUploading = true;
        this.audioUploadProgress = 0;
        this.audioUploadComplete = false;
        try {
            const response = await getPreSignedUrlAudio(file.name, file.type);
            const { presigned_url, file_name } = response.data.data;

            await uploadFileToS3(presigned_url, file, file.type);

            runInAction(() => {
                this.audioUploadComplete = true;
                this.audioURL = file_name;
            });
        } catch (error) {
            throw error;
        } finally {
            runInAction(() => {
                this.isAudioUploading = false;
            });
        }
    }

    uploadImage = async (file: File): Promise<string> => {
        try {
            const response = await getPreSignedUrlImage(file.name, file.type);
            const { presigned_url, file_name } = response.data.data;

            await uploadFileToS3(presigned_url, file, file.type);

            return file_name;
        } catch (error) {
            throw error;
        }
    }

    updateTrackSplit = async (data: TrackSplittingType[]) => {
        this.setLoading(true);
        try {
            const updatedImage = data.map((track) => this.getUrlEndpoint(track.image));
            const requestBody = {
                track_splitting: data.map((track, index) => ({
                    ...track,
                    image: updatedImage[index]
                }))
            }
            await updateTrackSplitData(this.concertData!._id!, requestBody);
        } catch (error) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    setConcertDuration = (duration: string) => {
        this.concertDuration = duration;
    }

    getUrlEndpoint = (fullUrl: string) => {
        try {
            const url = new URL(fullUrl);
            const endpoint = url.pathname.startsWith("/") ? url.pathname.slice(1) : url.pathname;
            return endpoint;
        } catch (error) {
            return fullUrl;
        }
    };

}

export default ConcertStore;
