import { makeAutoObservable, runInAction } from "mobx";
import { Store, IDashboardStore, AllTransactionsAndAmountCount } from "../global/types";
import { getAllTransactionsAndAmountCountData } from "../api/dashboard.action";

interface TransactionData {
    timePeriod: "day" | "week" | "all";
    totalAmount: number;
    transactionCount: number;
}
  
class DashboardStore implements IDashboardStore {
    rootStore: Store;
    allTransactionsAndAmountCount: AllTransactionsAndAmountCount[] = [];
    error: string | null = null;
    loading: boolean = false; 

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllTransactionsAndAmountCount = async () => {
        this.setLoading(true);
        try {
            const response = await getAllTransactionsAndAmountCountData()
            runInAction(() => {
                const allTransactionsAndAmountCount = response.data?.data || [];
             
                const defaultData = {
                  day: { timePeriod: "day", totalAmount: 0, transactionCount: 0 },
                  week: { timePeriod: "week", totalAmount: 0, transactionCount: 0 },
                  all: { timePeriod: "all", totalAmount: 0, transactionCount: 0 }
                }
              
                const data: any = allTransactionsAndAmountCount || [];
                data.forEach((item: TransactionData) => {
                  defaultData[item.timePeriod] = item;
                })
                this.allTransactionsAndAmountCount = Object.values(defaultData)
            })
        } catch (error: unknown) {
            throw error
        } finally {
            this.setLoading(false);
        }
    }

}

export default DashboardStore;
