import { makeAutoObservable, runInAction } from "mobx";
import { Store, ITransactionHistoryStore, AllTransactionHistoryData, AllConcertsForTransactionHistoryData } from "../global/types";
import { getAllConcertsForTransactionHistoryData, getAllTransactionHistoryData } from "../api/transactionHistoryAction";

class TransactionHistoryStore implements ITransactionHistoryStore {
    rootStore: Store;
    allTransactionHistoryData: AllTransactionHistoryData[] = [];
    allConcertsForTransactionHistoryData : AllConcertsForTransactionHistoryData[] = [];
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    transactionStatus: string = '';
    concertTitle: string = '';
    startDate: string | Date = '';
    endDate: string | Date = '' ;
    totalItems: number = 0;
    totalPages: number = 0;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllTransactionHistoryData = async (
        action: 'change_page' | 'search' | 'change_limit' | 'change_transaction_status' | 'change_concert_title' | 'change_date_range' |'',
        page: number,
        searchTerm: string,
        limit: number,
        transactionStatus: string,
        concertTitle: string,
        startDate: string | Date,
        endDate: string | Date
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_limit':
                        this.limit = limit
                        this.page = 1
                        break;
                    case 'change_transaction_status':
                        this.transactionStatus = transactionStatus
                        this.page = 1
                        break;
                    case 'change_concert_title':
                        this.concertTitle = concertTitle
                        this.page = 1
                        break;
                    case 'change_date_range':
                        this.startDate = startDate
                        this.endDate = endDate
                        this.page = 1
                        break;
                    default:
                        break;
                }
            })
            const data = {
                page: this.page,
                limit: this.limit,
                search: this.searchTerm?.trim(),
                transaction_status: this.transactionStatus.toUpperCase(),
                concert_id: this.concertTitle,
                start_date: this.startDate,
                end_date: this.endDate
            }
            const response = await getAllTransactionHistoryData(data);
            runInAction(() => {
                this.allTransactionHistoryData = (response.data?.data?.transactions || []).map((transaction: any) => {
                    return {
                        ...transaction,  // Spread the existing transaction data
                        card: `${transaction.card_brand} **** ${transaction.card_last4}`,
                        amount: `$${transaction.amount.toFixed(2)}`, 
                    };
                });
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            })
        } catch (error: unknown) {
            throw error
        } finally {
            this.setLoading(false);
        }
    }

    fetchAllConcertsForTransactionHistoryData = async () => {
        this.setLoading(true);
        try {
            const response = await getAllConcertsForTransactionHistoryData();
            runInAction(() => {
                this.allConcertsForTransactionHistoryData = [
                    { _id: 'All', title: 'All' }, // Add 'All' option
                    ...response.data?.data.map((concert: { _id: string; title: string }) => ({
                        _id: concert._id,
                        title: concert.title,
                    })) || [],
                ]
            })
        } catch (error: unknown) {
            throw error
        } finally {
            this.setLoading(false);
        }
    }
}

export default TransactionHistoryStore;
