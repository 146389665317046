import { makeAutoObservable, runInAction } from "mobx";
import { AdminData, IAuthStore, Role, Store } from "../global/types";
import axios from "axios";
import { changePassword, editUserProfile, getUserProfile, logIn, logOut, requestOtp, resetPassword, verifyOtp } from "../api/user.action";

class AuthStore implements IAuthStore {
    rootStore: Store;
    authToken: string = "";
    isAuthenticated: boolean = false;
    role: Role | undefined;
    loading: boolean = false;
    adminData: AdminData | null = null;

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
        this.initializeFromLocalStorage();
    }
    
    initializeFromLocalStorage = () => {
        const token = localStorage.getItem("token");
        const role = localStorage.getItem("role") as Role | null;
        
        if (token) {
            this.setAuthToken(token);
        }
        
        if (role) {
            this.setRole(role);
        }
    }

    setAuthToken = (token: string | null) => {
        if (!token) {
            this.authToken = "";
            this.isAuthenticated = false;
            delete axios.defaults.headers.common["Authorization"];
            localStorage.removeItem("token");
            return;
        }

        this.authToken = token;
        this.isAuthenticated = true;
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        localStorage.setItem("token", token);
    }

    setRole = (role: Role) => {
        this.role = role;
        localStorage.setItem("role", role);
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    isSuperAdmin = () => {
        return this.role === 'SUPER_ADMIN';
    }

    login = async (email: string, password: string) => {
        this.setLoading(true);
        try {
            const response = await logIn(email, password);
            const { token, role } = response.data.data;
            
            runInAction(() => {
                this.setAuthToken(token);
                this.setRole(role);
            });
        } catch (error) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    logout = async () => {
        this.setLoading(true);
        try {
            await logOut();
            runInAction(() => {
                this.setAuthToken(null);
                this.role = undefined;
                localStorage.removeItem("role");
            });
        } catch (error: unknown) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    requestChangePassword = async (oldPassword: string, newPassword: string) => {
        this.setLoading(true);
        try {
            const requestData = {
                old_password: oldPassword,
                new_password: newPassword
            }
            await changePassword(requestData);
        } catch (error: unknown) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    getOtp = async (email: string) => {
        this.setLoading(true);
        try {
            await requestOtp(email);
        } catch (error: unknown) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    confirmOtp = async (email: string, otp: string) => {
        this.setLoading(true);
        try {
            const requestData = {
                email,
                otp
            }
            await verifyOtp(requestData);
        } catch (error: unknown) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    requestResetPassword = async (email: string, newPassword: string) => {
        this.setLoading(true);
        try {
            const requestData = {
                email,
                new_password: newPassword
            }
            await resetPassword(requestData);
            return;
        } catch (error: unknown) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    fetchUserProfile = async () => {
        this.setLoading(true);
        try {
            const response = await getUserProfile();
            runInAction(() => {
                this.adminData = response.data?.data;
            })
        } catch (error: unknown) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

    updateUserProfile = async (name: string) => {
        this.setLoading(true);
        try {
            const response = await editUserProfile(name);
            runInAction(() => {
                this.adminData = response.data?.data;
            })
        } catch (error: unknown) {
            throw error;
        } finally {
            this.setLoading(false);
        }
    }

 }

export default AuthStore;
